// These are things (events) that can happen that is related to authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const NOTIFICATION_STATE_PENDING = 'NOTIFICATION_STATE_PENDING';
export const NOTIFICATION_STATE_CHOSEN = 'NOTIFICATION_STATE_CHOSEN';

export const OPEN_ADD_CHAT_POPUP = "OPEN_ADD_CHAT_POPUP";
export const CLOSE_ADD_CHAT_POPUP = "CLOSE_ADD_CHAT_POPUP";

export const OPEN_JOIN_CHAT_POPUP = "OPEN_JOIN_CHAT_POPUP";
export const CLOSE_JOIN_CHAT_POPUP = "CLOSE_JOIN_CHAT_POPUP";

export const OPEN_USER_PROFILE_POPUP = "OPEN_USER_PROFILE_POPUP";
export const CLOSE_USER_PROFILE_POPUP = "CLOSE_USER_PROFILE_POPUP";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const WAITING_FOR_OLD_MESSAGES = "WAITING_FOR_OLD_MESSAGES";
export const LOAD_OLD_MESSAGES = "LOAD_OLD_MESSAGES";
export const SET_MESSAGES = "SET_MESSAGES";

export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
