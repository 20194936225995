export const homeObjOne = {
    lightBg: false,
    primary:true,
    imgStart:'',
    lightTopLine:true,
    lightText:true,
    lightTextDesc:true,
    buttonLabel:'Get Started',
    desciption: 'Stay connected and communicate easily',
    headline:'Lessonger - Learn Together!',
    topline:'Online chatting platform',
    alt:'Image',
    start:''
    
};

