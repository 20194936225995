export const openKLMS = () => {
  return {
    type: "OPEN_KLMS"
  };
};

export const closeKLMS = () => {
  return {
    type: "CLOSE_KLMS"
  };
};